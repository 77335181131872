<template>
  <main class="s-expand">
    <div class="formSectionHolder" v-bind:class="'step--'+step">
      <transition name="fade" @after-enter="afterEnter" mode="out-in">
        <section v-if="step == 0" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s0">
          <div class="s-wrapper s-relative s-expand">
            <h1 class="s-text-size--5xl">You access has expired.</h1>
            <p class="s-text-size--xl">If you still need access to Spell Book, please reapply.</p>
            <router-link :to="{ 'name': 'form' }" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Reapply for Demo Tour</router-link>
          </div>
        </section>
        <section v-else-if="step == 1" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s1">
          <div class="s-wrapper s-relative s-expand">
            <form>
              <h1 class="s-text-size--5xl"><span class="capitalize">{{submission.name}}</span>,<br/>welcome to Spell Book!</h1>
              <p class="s-text-size--xl">We hope that you’ll enjoy your access to the Spell Book and will find the answers you are looking for. <br/><br/> After reviewing some general concepts you’ll be able to ask your questions and schedule a personal case review.<br/><br/>
        Looking forward to meeting you,<br/>
        🧙🏻‍♂️ Spell Team</p>
              <button @click.prevent="step = 2" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Open the Spell Book<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
            </form>
          </div>
        </section>
        <section v-else-if="step == 2" class="formSection s-swatch-black s-flex--items animate s2">
          <div class="s-wrapper s-relative s-expand">
            <div class="s-flex--stackable-phone-content--spaceBetween">
              <div class="s-col-7 s-margin-bottom--m">
                <div class="video-placeholder video-placeholder--active s-shape-rounded">
                  <div class="video-aspect">
                    <iframe v-if="showVideo" id="video-player" width="560" height="315" allow="autoplay; fullscreen"
                      :src="getEmbed(videos[currentVideo].video)" frameborder="0"
                      onload="onYouTubeIframeAPIReady()"
                      allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              <div class="s-items-spacingVertical--s s-col-4 s-overflow--hidden">
                <a href="" v-for="(v, key) in videos" :key="v.title" @click.prevent="key - 1 <= unlockedVideo ? currentVideo = key : null" class="s-flex video-button" v-bind:class="{ 'video-button--active' : key === currentVideo }">
                  <div class="video-placeholder video-placeholder--small s-relative s-blockWidth--9" :class="{ 'video-placeholder--play' : key !== currentVideo && key - 1 <= unlockedVideo }">
                    <img :src="getThumb(v.video)" />
                    <div v-if="key !== currentVideo && key - 1 <= unlockedVideo" class="s-position--center icon-play-circle-line s-text-size--4xl-text-color--white" />
                    <div v-if="key - 1 > unlockedVideo" class="s-position--center icon-lock-2-line s-text-size--3xl-text-color--white" />
                  </div>
                  <div class="s-flex--items--center s-text--semibold s-text-size--l s-margin-left--m">{{v.title}}</div>
                </a>
                <button v-if="!video" v-bind:disabled="unlockedVideo + 1 < videos.length" @click.prevent="step = 3" :class="unlockedVideo + 1 < videos.length ? 's-border--outline--thick s-swatch-pink' : 's-swatch-primary'" class="s-button s-relative s-shape-pill ">
                  <span v-if="unlockedVideo + 1 < videos.length" class="icon-lock-2-fill s-margin-right--xs s-text--semibold"></span>
                  Schedule a meeting
                  <span v-if="unlockedVideo + 1 == videos.length" class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span>
                  <span class="video-line"></span>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section v-else-if="step == 3" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s3">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="answerQuestion">
              <h1 class="s-text-size--5xl">{{ questions[0] }}</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <textarea v-on:input="onInput" rows="1" autofocus capitalize v-model="answers[0]" required class="s-col-6 s-text-size--xl" placeholder="Type your answer here...."></textarea>
                <div class="s-margin-top--xl">
                  <h2 class="s-text-size--l">{{ questions[1] }}</h2>
                  <div class="s-flex--items--start-stackable-phone s-expand s-items-spacing--m">
                    <div class="s-radiobox">
                      <input type="radio" name="a" id="a1" v-model="answers[1]" value="E-commerce Suite" required />
                      <label for="a1">E-commerce Suite</label>
                    </div>
                    <div class="s-radiobox">
                      <input type="radio" name="a" id="a2" v-model="answers[1]" value="Accounts Suite" required />
                      <label for="a2">Accounts Suite</label>
                    </div>
                    <div class="s-radiobox">
                      <input type="radio" name="a" id="a3" v-model="answers[1]" value="United Solution" required />
                      <label for="a3">United Solution</label>
                    </div>
                  </div>
                </div>
                <button type="submit" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Move on<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 4" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s4">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="answerQuestion">
              <h1 class="s-text-size--5xl">{{ questions[2] }}</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <textarea v-on:input="onInput" rows="1" autofocus capitalize v-model="answers[2]" required class="s-col-6 s-text-size--xl" placeholder="Type your answer here...."></textarea>
                <button type="submit" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Move on<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 5" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s5">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="answerQuestion">
              <h1 class="s-text-size--5xl">{{ questions[3] }}</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <textarea v-on:input="onInput" rows="1" autofocus capitalize v-model="answers[3]" required class="s-col-6 s-text-size--xl" placeholder="Type your answer here...."></textarea>
                <button type="submit" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Move on<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 6" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s6">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="answerQuestion">
              <h1 class="s-text-size--5xl">{{ questions[4] }}</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <textarea v-on:input="onInput" rows="1" autofocus capitalize v-model="answers[4]" required class="s-col-6 s-text-size--xl" placeholder="Type your answer here...."></textarea>
                <button type="submit" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Move on<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 7" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s7">
          <div class="s-wrapper s-relative s-expand" v-if="showQueustion">
            <form @submit.prevent="step = step + 1">
              <h1 class="s-text-size--5xl">Do you have any questions about Spell left for now?<br/>Please list those to prepare for the review.</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <textarea v-on:input="onInput" rows="1" autofocus capitalize v-model="userQuestions[0]" required class="s-col-6 s-text-size--xl s-margin-bottom--m" placeholder="Your questions"></textarea>
                <div class="s-flex--stackable-phone s-items-spacing--m">
                  <button type="submit" class="s-button s-swatch-primary s-shape-pill">Pick date &amp; time<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
                  <button @click.prevent="step = 8" class="s-button s-border--outline s-shape-pill">Skip<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
                </div>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 8" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s8">
          <div class="s-wrapper s-relative s-expand">
            <div class="calendly-inline-widget s-expand s-height--expand s-width--expand s-flex-direction--column" data-auto-load="false"></div>
          </div>
        </section>
      </transition>
    </div>
  </main>
</template>

<script>
/* eslint-disable no-undef */
import { apiClient } from '../api/api-client'
export default {
  // You can specify layout component name here (default value is 'default')
  layout: 'form',
  props: {
    video: { type: Boolean },
    book: { type: Boolean }
  },
  mounted () {
    if (this.$props.video) {
      this.step = 2
    } else {
      apiClient.get(`/book/${this.$route.params.id}`).then(res => {
        if (res && res.data && res.data.id) {
          this.submission = res.data
          if (this.$props.book) {
            this.step = 8
          } else {
            this.step = 1
          }
        } else {
          this.step = 0
        }
      }).catch(e => {
        this.step = 0
      })
    }
    startBg()
    window.history.replaceState({ step: this.step }, '', '')
    window.onpopstate = (event) => {
      var step = event.state && event.state.step ? event.state.step : 1
      this.step = step
    }
    this.setupYoutube()
  },
  data () {
    return {
      step: -1,
      submission: null,
      videos: [
        { title: 'Why choose Spell?', text: '', video: 'https://www.youtube.com/watch?v=CCjSDa9w-GA' },
        { title: 'Spell Platform Setup Types', text: '', video: 'https://www.youtube.com/watch?v=OBCZhEPnz6k' },
        { title: 'Introduction to Front Office', text: '', video: 'https://www.youtube.com/watch?v=8tFmWRSVRLc' },
        { title: 'Introduction to Back Office', text: '', video: 'https://www.youtube.com/watch?v=1YgFmeDElKo' }
      ],
      currentVideo: 0,
      unlockedVideo: 0,
      showVideo: false,
      questions: [
        'Please describe your business in brief and pick the appropriate Spell platform setup type.',
        'Select setup type',
        'Please specify some information on the targeted markets and expected business volumes.',
        'Does your company have a financial license? If yes, then please specify the country of issue and license type.',
        'Does your company already have partnerships in place? If yes, then please specify these partners.'
      ],
      question: 0,
      answers: new Array(5),
      answer: '',
      userQuestions: [''],
      userQuestion: '',
      showQueustion: true
    }
  },
  watch: {
    step (val, oldVal) {
      this.$nextTick(() => {
        const input = document.querySelector('input[autofocus]')
        if (input) {
          input.focus()
        }
        document.querySelector('header').style.visibility = this.step === 8 ? 'hidden' : ''
        document.querySelector('.s-headerTitle').style.visibility = this.step !== 2 ? 'hidden' : 'visible'
        if (this.step === 8) {
          setTimeout(() => {
            this.enableCalendar()
          }, 1000)
          this.submit()
        }
        if (this.step === 2) {
          this.setupVideo()
        }
      })
      if (val > oldVal) {
        if (this.replaceState) {
          window.history.replaceState({ step: this.step }, '', '')
          this.replaceState = false
        } else {
          window.history.pushState({ step: this.step }, '', '')
        }
      }
    },
    currentVideo (val) {
      this.unlockedVideo = Math.max(val, this.unlockedVideo)
      window.onYouTubeIframeAPIReady()
    }
  },
  methods: {
    getThumb (url) {
      if (url === null) {
        return ''
      }
      const results = url.match('[\\?&]v=([^&#]*)')
      const video = (results === null) ? url : results[1]

      return 'https://img.youtube.com/vi/' + video + '/0.jpg'
    },
    getEmbed (url) {
      if (url === null) {
        return ''
      }
      const results = url.match('[\\?&]v=([^&#]*)')
      const video = (results === null) ? url : results[1]

      return 'https://www.youtube.com/embed/' + video + '?enablejsapi=1&autoplay=1'
    },
    answerQuestion () {
      if (this.step === 3) {
        if (!this.answers[0] || !this.answers[1]) {
          return
        }
      }
      if (this.step === 4) {
        if (!this.answers[2]) {
          return
        }
      }
      if (this.step === 5) {
        if (!this.answers[3]) {
          return
        }
      }
      if (this.step === 6) {
        if (!this.answers[4]) {
          return
        }
      }
      this.step = this.step + 1
    },
    addQuestion () {
      this.userQuestions.push('')
      this.$nextTick(() => {
        const textareas = document.querySelectorAll('textarea');
        [...textareas].forEach(el => this.updateTextareaHeight(el))
      })
    },
    calendarAnswers () {
      const texts = []
      const answers = this.answers.filter(x => x !== '')
      if (answers.length > 0) {
        texts.push('Answers to question:')
        answers.forEach((x, i) => texts.push(`${i + 1}. ${x}`))
      }
      const questions = this.userQuestions.filter(x => x !== '')
      if (questions.length > 0) {
        texts.push(`\nQuestions to Spell:`)
        questions.forEach((x, i) => texts.push(`${i + 1}. ${x}`))
      }
      return texts.join(`\n`)
    },
    enableCalendar () {
      const plugin = document.createElement('script')
      plugin.setAttribute(
        'src',
        '//assets.calendly.com/assets/external/widget.js'
      )
      plugin.async = true
      plugin.onload = () => {
        this.$nextTick(() => {
          Calendly.initInlineWidget({
            url: 'https://calendly.com/spelltosell/case-review?text_color=1f146d&primary_color=3322b5',
            parentElement: document.querySelector('.calendly-inline-widget'),
            prefill: {
              name: this.submission.name,
              email: this.submission.email,
              customAnswers: {
                a1: this.submission.company || '-',
                a2: this.submission.web || '-',
                a3: this.submission.linkedin || '-',
                a4: this.calendarAnswers()
              }
            }
          })
        })
      }
      document.head.appendChild(plugin)

      window.addEventListener(
        'message',
        (e) => {
          if (e.data.event && e.data.event.indexOf('calendly') === 0) {
            if (e.data.event === 'calendly.event_scheduled') {
              this.$router.push({ name: 'demo' })
            }
          }
        }
      )
    },
    setupVideo () {
      if (!this.showVideo) {
        this.showVideo = true
        this.$nextTick(() => {
          onYouTubeIframeAPIReady()
        })
      }
    },
    setupYoutube () {
      window.onYouTubeIframeAPIReady = () => {
        // eslint-disable-next-line no-unused-vars
        const player = new YT.Player('video-player', {
          events: {
            onReady: (event) => {
              event.target.playVideo()
              if (event.target.getPlayerState()) {
                this.unlockedVideo = this.currentVideo
              }
            },
            onStateChange: (e) => {
              if (e.data > 0) {
                this.unlockedVideo = this.currentVideo
              } else if (e.data === 0) {
                this.currentVideo = Math.min(this.videos.length - 1, this.currentVideo + 1)
              }
            }
          }
        })
      }
      var tag = document.createElement('script')
      tag.id = 'iframe-youtube'
      tag.src = 'https://www.youtube.com/iframe_api'
      var firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    },
    submit () {
      apiClient.post(`/book/${this.submission.id}`, {
        answers: this.answers,
        questions: this.userQuestions.filter(x => x !== '')
      })
    },
    updateTextareaHeight (el) {
      el.style.height = 'auto'
      el.style.height = (el.scrollHeight) + 'px'
    },
    afterEnter (el) {
      const input = el.querySelector('[autofocus]')
      if (input) {
        input.focus()
      }
      const textareas = el.querySelectorAll('textarea');
      [...textareas].forEach(el => this.updateTextareaHeight(el))
    },
    onInput (e) {
      this.updateTextareaHeight(e.target)
    }
  }
}
</script>
<style lang="less" scoped>
  input,
  textarea {
    border-radius: 0;
    border: 0;
    background: transparent;
    color: #fff;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: #fff !important;
    padding-left: 0;
    box-shadow: none;

    .formSection &:focus {
      box-shadow: none !important;
      border-bottom-color: #ff9ffe !important;
    }

    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
    &:-moz-placeholder {
        color: rgba(255, 255, 255, 0.8);
    }
    &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.8);
    }
    &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.8);
    }
  }
  textarea {
    min-height: 1.9rem;
    resize: none;
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .formSectionHolder {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    padding: 110px 0;

    &.step--8 {
      padding: 0;
    }
  }
  .formSection {
    width: 100%;
    background: transparent !important;
    position: relative;
    min-height: 70vh;
    transform: translate3d(0, 0, 0);
    display: flex;
    align-items: center;

    h1,
    h2,
    h3,
    p {
      max-width: 900px;
    }
  }
  .progress-container {
    position: absolute;
    bottom: 40px;
  }
  .progress {
    position: relative;
    height: 6px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.4);
  }
  .progress-inner {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
  .video-aspect {
    position: relative;
    padding-bottom: 56.25%;
  }
  .video-button {
    &:hover {
      .video-placeholder {
        &:before {
          opacity: 1;
        }
      }
    }
    &--active {
      .video-placeholder {
        &:before {
          opacity: 1;
          box-shadow: inset 0 0 0 3px #E4A2E8;
        }
        &:after {
          opacity: 0;
        }
      }
    }
    .video-placeholder {
      border-radius: 3px;
    }
  }
  .video-placeholder {
    background: #000;
    overflow: hidden;
    position: relative;
    border-radius: 3px;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      box-shadow: inset 0 0 0 3px #ff9ffe;
      content: "";
      opacity: 0;
      transition: opacity .1s;
    }

    &--active {
      border: 3px solid #f1a6f6;
      box-shadow:0 0 50px 0 rgba(#f1a6f6, 0.8);
      &:before {
        display: none;
      }
    }

    &--small {
      height: 5em;

      &:after {
        content: "";
        position: absolute;
        background: #E4A2E8;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
      }

      [class*="icon-"] {
        z-index: 1;
      }
    }

    &--play {
      &:after {
        opacity: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  .video-line {
    position: absolute;
    width: 2px;
    bottom: 100%;
    left: 35px;
    height: 10000px;
    background: #ff9ffe;
    z-index: -1;
  }
  .capitalize {
    text-transform: capitalize;
    &::placeholder {
      text-transform: none;
    }
  }
  button {
    cursor: pointer;
  }
  .fade-enter-active,
  .fade-leave-active {
    position: fixed;
    transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  }
  .fade-enter-from {
    opacity: 0;
    transform: translateY(100vh);
  }
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-100vh);
  }
  .s-radiobox {
    position: relative;
    input {
      position: absolute;
      left: 6px;
      top: 10px;
      width: 10px;
      opacity: 0;
    }
    label {
      position: relative;
      cursor: pointer;

      &:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #fff;
        vertical-align: top;
        margin-right: 0.5em;
        position: relative;
        top: 2px;
      }

      &:after {
        content: "";
        position: absolute;
        background: #fff;
        width: 10px;
        height: 10px;
        left: 4px;
        top: 4px;
        border-radius: 50%;
        opacity: 0;
      }
    }

    input:checked + label {
      &:after {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 560px) {
    .formSection {
      align-items: flex-start;
    }
  }
</style>
